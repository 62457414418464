import { WordDictionary } from "@app/shared/interfaces/word-dictionary.interface";
import { IEmptyState } from "@app/shared/interfaces/empty-state-text.interface";

export const chooseAssociationText = 'Choose your association to get started.';

const contactWordDictionary: WordDictionary= {
  '' : ''
}

export const generateContactEmptyState = (association: boolean = false, type: string, title: string, category: string = ''): IEmptyState => {
  let text: IEmptyState = {title: '',  subtitle: ''};
  if(association) {
    text.title = `No ${title} to Display Yet`;
    text.subtitle = `Choose your ${category ? category : 'association'} to get started.`
  } else {
    text.title = `No ${title} Yet`;
    text.subtitle = `You haven't received any ${contactWordDictionary[type] || type} yet`;
  }

  return text;
}
